import React from "react"
import { postTypesSlug } from "../../content/Global"
import ContentSection from "./ContentSection"
import Button from "../atoms/Button"
import { resourcesPage } from "../../content/ResourcesPage"
import InnerHeader from "./InnerHeader"

const InnerResourcePage = ({ post }) => {
  return (
    <>
      <InnerHeader
        title={post.title}
        googleReviewText={resourcesPage.googleReviewText}
        description={post.description}
      />

      <div className="cc-section--horizontal-space">
        <div className="pt-0 md:pt-10 pb-5">
          {post.contentSections.map((section, index) => (
            <ContentSection
              key={index}
              title={section.title}
              titleEC={section.titleEC}
              description={section.description}
              list={section.list}
              image={section.image}
              imageEC={section.imageEC}
            />
          ))}
        </div>
        <div className="pt-10 pb-20 text-center">
          <Button
            to={postTypesSlug.resourcesSlug}
            href={postTypesSlug.resourcesSlug}
            text={`Go to Resources`}
            ec={`primary-btn`}
          />
        </div>
      </div>
    </>
  )
}

export default InnerResourcePage
